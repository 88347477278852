// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";
const ROOTS_STORE = "/store";
const ROOTS_BLOG = "/blog";
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
};
// export const PATH_BLOG = {
//   root: ROOTS_DASHBOARD,
//   blog: {
//     root: path(ROOTS_BLOG, "/blog"),
//     blogList: {
//       root: path(ROOTS_BLOG, "/blog/list"),
//       create: path(ROOTS_BLOG, "/blog/list/create"),
//       edit: (id) => path(ROOTS_BLOG, `/blog/list/${id}/edit`),
//     },
//     blogCategory: {
//       root: path(ROOTS_BLOG, "/blogCategory/list"),
//       create: path(ROOTS_BLOG, "/blogCategory/list/create"),
//       edit: (id) => path(ROOTS_BLOG, `/blogCategory/list/${id}/edit`),
//     },
//     blogTag: {
//       root: path(ROOTS_BLOG, "/blogTag/list"),
//       create: path(ROOTS_BLOG, "/blogTag/list/create"),
//       edit: (id) => path(ROOTS_BLOG, `/blogTag/list/${id}/edit`),
//     },
//   },
// };
export const PATH_STORE = {
  root: ROOTS_DASHBOARD,
  // pageInfo: {
  //   root: path(ROOTS_STORE, "/pageInfo/list"),
  //   create: path(ROOTS_STORE, "/pageInfo/list/create"),
  //   edit: (id, slug) => path(ROOTS_STORE, `/pageInfo/list/${id}/${slug}/edit`),
  //   // pageInfoCategory: {
  //   //   root: path(ROOTS_STORE, "/pageInfo/pageInfoCategory/list"),
  //   //   create: path(ROOTS_STORE, "/pageInfo/pageInfoCategory/list/create"),
  //   //   edit: (id) =>
  //   //     path(ROOTS_STORE, `/pageInfo/pageInfoCategory/list/${id}/edit`),
  //   // },ّ
  // },
  // products: {
  //   root: path(ROOTS_STORE, "/products"),
  //   catsList: {
  //     root: path(ROOTS_STORE, "/products/cats"),
  //     create: path(ROOTS_STORE, "/products/cats/create"),
  //     edit: (id) => path(ROOTS_STORE, `/products/cats/${id}/edit`),
  //   },
  //   productsList: {
  //     root: path(ROOTS_STORE, "/products/list"),
  //     // list: path(ROOTS_STORE, '/products/list'),

  //     order: path(ROOTS_STORE, "/products/list/order"),
  //     create: path(ROOTS_STORE, "/products/list/create"),
  //     edit: (slug, id) =>
  //       path(ROOTS_STORE, `/products/list/${slug}/${id}/edit`),
  //   },
  //   productAttribute: {
  //     detail: path(ROOTS_STORE, "/products/detail"),
  //     create: path(ROOTS_STORE, "/products/detail/create"),
  //     edit: (id) => path(ROOTS_STORE, `/products/detail/${id}/edit`),
  //     add: (attrId) =>
  //       path(ROOTS_STORE, `/products/detail/${attrId}/from-product`),
  //   },
  //   productsOrderList: {
  //     root: path(ROOTS_STORE, "/products/order"),
  //   },
  // },
  category: {
    root: path(ROOTS_STORE, "/category"),
    create: path(ROOTS_STORE, "/category/list/create"),
    edit: (id) => path(ROOTS_STORE, `/category/list/${id}/edit`),
  },
  menus: {
    root: path(ROOTS_STORE, "/menus"),
    create: path(ROOTS_STORE, "/menus/list/create"),
    edit: (id) => path(ROOTS_STORE, `/menus/list/${id}/edit`),
  },
  mainCategory: {
    root: path(ROOTS_STORE, "/mainCategory"),
    create: path(ROOTS_STORE, "/mainCategory/list/create"),
    edit: (id) => path(ROOTS_STORE, `/mainCategory/list/${id}/edit`),
  },
  faq: {
    root: path(ROOTS_STORE, "/faq"),
    faqList: {
      root: path(ROOTS_STORE, "/faq/list"),
      create: path(ROOTS_STORE, "/faq/list/create"),
      edit: (id) => path(ROOTS_STORE, `/faq/list/${id}/edit`),
      add: (attrId) => path(ROOTS_STORE, `/faq/list/${attrId}/from-product`),
    },
    category: {
      root: path(ROOTS_STORE, "/faq/category"),
      create: path(ROOTS_STORE, "/faq/category/create"),
      edit: (id) => path(ROOTS_STORE, `/faq/category/${id}/edit`),
    },
  },
  questionSurvey: {
    root: path(ROOTS_STORE, "/questionSurvey"),
    list: {
      root: path(ROOTS_STORE, "/questionSurvey/list"),
      create: path(ROOTS_STORE, "/questionSurvey/list/create"),
      edit: (id) => path(ROOTS_STORE, `/questionSurvey/list/${id}/edit`),
    },
  },

  roles: {
    root: path(ROOTS_STORE, "/roles"),
    create: path(ROOTS_STORE, "/roles/list/create"),
    edit: (id) => path(ROOTS_STORE, `/roles/list/${id}/edit`),
  },
  profiles: {
    root: path(ROOTS_STORE, "/profiles"),
    create: path(ROOTS_STORE, "/profiles/list/create"),
    edit: (id) => path(ROOTS_STORE, `/profiles/list/${id}/edit`),
  },

  users: {
    root: path(ROOTS_STORE, "/users"),
    create: path(ROOTS_STORE, "/users/list/created"),
    edit: (id) => path(ROOTS_STORE, `/users/list/${id}/edit`),
    editEdit: (id) => path(ROOTS_STORE, `/users/list/${id}/users/edit`),
    show: (id) => path(ROOTS_STORE, `/users/list/${id}/status`),
    showInfo: (id) => path(ROOTS_STORE, `/users/list/${id}/attributes`),
  },
  userAttributes: {
    root: path(ROOTS_STORE, "/userAttributes"),
    create: path(ROOTS_STORE, "/userAttributes/list/create"),
    edit: (id) => path(ROOTS_STORE, `/userAttributes/list/${id}/edit`),
  },
  permission: {
    root: path(ROOTS_STORE, "/permission"),
    create: path(ROOTS_STORE, "/permission/list/create"),
    edit: (id) => path(ROOTS_STORE, `/permission/list/${id}/edit`),
  },
  group: {
    root: path(ROOTS_STORE, "/group"),
    create: path(ROOTS_STORE, "/group/list/create"),
    edit: (id) => path(ROOTS_STORE, `/group/list/${id}/edit`),
  },
  news: {
    root: path(ROOTS_STORE, "/news"),
    newsList: {
      root: path(ROOTS_STORE, "/news/list"),
      create: path(ROOTS_STORE, "/news/list/create"),
      edit: (id) => path(ROOTS_STORE, `/news/list/${id}/edit`),
    },
    newsCategory: {
      root: path(ROOTS_STORE, "/newsCategory/list"),
      create: path(ROOTS_STORE, "/newsCategory/list/create"),
      edit: (id) => path(ROOTS_STORE, `/newsCategory/list/${id}/edit`),
    },
    newsTag: {
      root: path(ROOTS_STORE, "/newsTag/list"),
      create: path(ROOTS_STORE, "/newsTag/list/create"),
      edit: (id) => path(ROOTS_STORE, `/newsTag/list/${id}/edit`),
    },
  },
  license: {
    root: path(ROOTS_STORE, "/license"),

    licenseList: {
      root: path(ROOTS_STORE, "/license/list"),
      create: path(ROOTS_STORE, "/license/list/create"),
      edit: (id) => path(ROOTS_STORE, `/license/list/${id}/edit`),
    },

    // products: {
    //   root: path(ROOTS_STORE, "/license/products"),
    //   create: path(ROOTS_STORE, "/license/products/create"),
    //   edit: (id) => path(ROOTS_STORE, `/license/products/${id}/edit`),
    //   add: (attrId) =>
    //     path(ROOTS_STORE, `/license/products/${attrId}/from-product`),
    // },
  },

  // consult: {
  //   root: path(ROOTS_STORE, "/consult/list"),
  //   create: path(ROOTS_STORE, "/consult/list/create"),
  //   edit: (id) => path(ROOTS_STORE, `/consult/list/${id}/edit`),
  // },
  comments: {
    root: path(ROOTS_STORE, "/comments/list"),
    create: path(ROOTS_STORE, "/comments/list/create"),
    edit: (id) => path(ROOTS_STORE, `/comments/list/${id}/edit`),
  },
  hiring: {
    root: path(ROOTS_STORE, "/hiring/list"),
    create: path(ROOTS_STORE, "/hiring/list/create"),
    edit: (id) => path(ROOTS_STORE, `/hiring/list/${id}/edit`),
  },
  // transaction: {
  //   root: path(ROOTS_STORE, "/transaction/list"),
  //   create: path(ROOTS_STORE, "/transaction/list/create"),
  //   edit: (id) => path(ROOTS_STORE, `/transaction/list/${id}/edit`),
  // },
  meetRooms: {
    root: path(ROOTS_STORE, "/meetRooms/list"),
  },
  // referrals: {
  //   root: path(ROOTS_STORE, "/referrals/list"),
  // },
  // campaign: {
  //   root: path(ROOTS_STORE, "/campaign/list"),
  // },
  // cart: {
  //   root: path(ROOTS_STORE, "/cart/list"),
  // },
  faqProfile: {
    root: path(ROOTS_STORE, "/faqProfile"),
    faqList: {
      root: path(ROOTS_STORE, "/faqProfile/list"),
      create: path(ROOTS_STORE, "/faqProfile/list/create"),
      edit: (id) => path(ROOTS_STORE, `/faqProfile/list/${id}/edit`),
      add: (attrId) =>
        path(ROOTS_STORE, `/faqProfile/list/${attrId}/from-product`),
    },
    category: {
      root: path(ROOTS_STORE, "/faqProfile/category"),
      create: path(ROOTS_STORE, "/faqProfile/category/create"),
      edit: (id) => path(ROOTS_STORE, `/faqProfile/category/${id}/edit`),
    },
  },

  ticket: {
    root: path(ROOTS_STORE, "/ticket"),
    ticketList: {
      root: path(ROOTS_STORE, "/ticket/list"),
      edit: (id) => path(ROOTS_STORE, `/ticket/list/${id}/edit`),
    },
    ticketCategory: {
      root: path(ROOTS_STORE, "/ticketCategory"),
      create: path(ROOTS_STORE, "/ticketCategory/list/create"),
      edit: (id) => path(ROOTS_STORE, `/ticketCategory/list/${id}/edit`),
    },
  },
  lms: {
    root: path(ROOTS_STORE, "/lms"),
    lmsList: {
      root: path(ROOTS_STORE, "/lms/list"),
      create: path(ROOTS_STORE, "/lms/list/create"),
      edit: (id) => path(ROOTS_STORE, `/lms/list/${id}/edit`),
    },
    QusList: {
      create: (id) => path(ROOTS_STORE, `/lms/list/${id}/QuestionCreate`),
      edit: (id) => path(ROOTS_STORE, `/lms/list/${id}/QuestionEdit`),
    },
    IELTSList: {
      root: path(ROOTS_STORE, "/IELTS/list"),
      create: path(ROOTS_STORE, "/IELTS/list/create"),
      edit: (id) => path(ROOTS_STORE, `/IELTS/list/${id}/edit`),
    },
    license: {
      root: path(ROOTS_STORE, "/lms/license"),
      create: path(ROOTS_STORE, "/lms/license/create"),
    },
  },
  topic: {
    root: path(ROOTS_STORE, "/topic"),
    list: {
      root: path(ROOTS_STORE, "/products/list"),
      edit: (id) => path(ROOTS_STORE, `/topic/list/${id}/edit`),
      add: (attrId) => path(ROOTS_STORE, `/topic/list/${attrId}/from-product`),
      // edit: (id) => path(ROOTS_STORE, `/ticket/list/${id}/edit`),
    },
  },
  userDoc: {
    root: path(ROOTS_STORE, "/userDoc"),
    list: {
      root: path(ROOTS_STORE, "/userDoc/list"),
      create: path(ROOTS_STORE, "/userDoc/list/create"),
      edit: (id) => path(ROOTS_STORE, `/userDoc/list/${id}/edit`),
    },
  },
  settings: {
    root: path(ROOTS_STORE, "/settings"),
    slider: {
      root: path(ROOTS_STORE, "/settings/slider"),
      create: path(ROOTS_STORE, "/settings/slider/create"),
      edit: (id) => path(ROOTS_STORE, `/settings/slider/${id}/edit`),
      createSlide: (sliderId) =>
        path(ROOTS_STORE, `/settings/slider/${sliderId}/slide/create`),
      editSlide: (sliderId, slideId) =>
        path(ROOTS_STORE, `/settings/slider/${sliderId}/slide/${slideId}/edit`),
    },
    settings: {
      root: path(ROOTS_STORE, "/settings/settings"),
    },
    // campaign: {
    //   root: path(ROOTS_STORE, "/settings/campaign"),
    //   create: path(ROOTS_STORE, "/settings/campaign/create"),
    //   edit: (id) => path(ROOTS_STORE, `/settings/campaign/${id}/edit`),
    // },
  },

  sell: {
    root: path(ROOTS_STORE, "/sell"),

    orders: {
      root: path(ROOTS_STORE, "/sell/orders"),
      view: (id) => path(ROOTS_STORE, `/sell/orders/${id}`),
    },

    discounts: {
      root: path(ROOTS_STORE, "/sell/discounts"),
      create: path(ROOTS_STORE, `/sell/discounts/create`),
      edit: (id) => path(ROOTS_STORE, `/sell/discounts/${id}/edit`),
    },
  },
  // media: {
  //   root: path(ROOTS_STORE, "/media"),
  //   mediaList: {
  //     root: path(ROOTS_STORE, "/media/list"),
  //     create: path(ROOTS_STORE, "/media/list/create"),
  //     edit: (id) => path(ROOTS_STORE, `/media/list/${id}/edit`),
  //   },
  // },
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    ecommerce: path(ROOTS_DASHBOARD, "/ecommerce"),
    analytics: path(ROOTS_DASHBOARD, "/analytics"),
    banking: path(ROOTS_DASHBOARD, "/banking"),
    booking: path(ROOTS_DASHBOARD, "/booking"),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, "/mail"),
    all: path(ROOTS_DASHBOARD, "/mail/all"),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, "/chat"),
    new: path(ROOTS_DASHBOARD, "/chat/new"),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, "/calendar"),
  kanban: path(ROOTS_DASHBOARD, "/kanban"),
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    new: path(ROOTS_DASHBOARD, "/user/new"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, "/e-commerce"),
    shop: path(ROOTS_DASHBOARD, "/e-commerce/shop"),
    list: path(ROOTS_DASHBOARD, "/e-commerce/list"),
    checkout: path(ROOTS_DASHBOARD, "/e-commerce/checkout"),
    new: path(ROOTS_DASHBOARD, "/e-commerce/product/new"),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-blazer-low-77-vintage/edit"
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-air-force-1-ndestrukt"
    ),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, "/invoice"),
    list: path(ROOTS_DASHBOARD, "/invoice/list"),
    new: path(ROOTS_DASHBOARD, "/invoice/new"),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit"
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5"
    ),
  },
  // blog: {
  //   root: path(ROOTS_DASHBOARD, "/blog"),
  //   posts: path(ROOTS_DASHBOARD, "/blog/posts"),
  //   new: path(ROOTS_DASHBOARD, "/blog/new"),
  //   view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
  //   demoView: path(
  //     ROOTS_DASHBOARD,
  //     "/blog/post/apply-these-7-secret-techniques-to-improve-event"
  //   ),
  // },
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
